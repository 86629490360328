import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {BUDGET_TYPE, HIDDEN_FEATURES, MODULE_TYPES, PERIOD_BUDGET_TYPE, PERIODICITY} from '../../constants';
import Util from '../../forecast-app/shared/util/util';
import RadioButton from './radio_button';
import Checkbox from './check_box';
import InputFieldV2 from './input_field';
import DropdownV2 from '../../forecast-app/shared/components/dropdowns/dropdown';
import InformationIcon from '../../images/information-icon';
import FixedRangePicker from './date-controls/fixed_range_picker';
import TooltipContainer from '../../forecast-app/shared/components/tooltips/tooltip_container';
import ProjectBudgetTypeControls from './project/project_budget_type_controls';
import FinancialLockIcon from './FinancialLockIcon';
import {hasSomePermission} from '../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../Permissions';
import {LabelDropdown} from '../dropdowns/label-dropdown/Label_dropdown';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';
import {hasModule} from '../../forecast-app/shared/util/ModuleUtil';
import CompanySetupUtil, {isFeatureHidden} from '../../forecast-app/shared/util/CompanySetupUtil';
import DuplicateProjectDropdown, {DuplicateProjectDropdownQuery} from './DuplicateProjectDropdown';
import ForecastQueryRenderer from '../../ForecastQueryRenderer';

class NewProjectModalDetailedSettings extends Component {
	getRateCardsOptions() {
		const rateCards = [
			{
				value: null,
				label: this.props.intl.formatMessage({id: 'rate_card_selector.no_rate_card_text'}),
				currency: this.props.companyCurrency,
			},
		];
		const enabledNotAdjustedRateCards = this.props.rateCards.filter(
			rateCard => !rateCard.node.parentRateCardId && !rateCard.node.disabled
		);
		rateCards.push(
			...enabledNotAdjustedRateCards.map(rateCard => ({
				value: rateCard.node.id,
				label: rateCard.node.name,
				currency: rateCard.node.currency,
			}))
		);
		return rateCards;
	}

	addLabel(labelId) {
		this.props.updateLabels(this.props.projectLabels.concat([labelId]));
	}

	removeLabel(labelIdToRemove) {
		this.props.updateLabels(this.props.projectLabels.filter(labelId => labelId !== labelIdToRemove));
	}

	render() {
		const hasPlaceholders = hasFeatureFlag('placeholders') && hasModule(MODULE_TYPES.PLACEHOLDERS);
		const {formatMessage} = this.props.intl;
		const lastPeriodEndDate =
			this.props.projectStartDate &&
			this.props.preCreatePeriods &&
			this.props.preCreateAmount &&
			this.props.defaultPeriodLength &&
			(this.props.defaultPeriodHoursAmount || this.props.defaultPeriodPriceAmount)
				? this.props.periodStartDateHovered
					? this.props.periodStartDateHovered
							.clone()
							.add(
								this.props.defaultPeriodLength * this.props.preCreateAmount,
								this.props.defaultPeriodPeriodicity === PERIODICITY.DAILY
									? 'days'
									: this.props.defaultPeriodPeriodicity === PERIODICITY.WEEKLY
									? 'weeks'
									: 'months'
							)
							.subtract(1, 'days')
					: this.props.periodStartDate
							.clone()
							.add(
								this.props.defaultPeriodLength * this.props.preCreateAmount,
								this.props.defaultPeriodPeriodicity === PERIODICITY.DAILY
									? 'days'
									: this.props.defaultPeriodPeriodicity === PERIODICITY.WEEKLY
									? 'weeks'
									: 'months'
							)
							.subtract(1, 'days')
				: null;

		const isUsingAllocations =
			this.props.isUsingProjectAllocation || (hasFeatureFlag('tp_heatmap_spike') && this.props.isUsingMixedAllocation);
		return (
			<div className={'project-third-info'}>
				{/* labels dropdown */}
				{this.props.useDuplication ? (
					<div className="project-labels-controls-wrapper empty" />
				) : (
					<div className="project-labels-controls-wrapper">
						<label className="label">{formatMessage({id: 'project_settings.project-labels'})}</label>
						<div>
							<LabelDropdown
								labels={this.props.labels}
								selectedItems={this.props.projectLabels}
								onSelect={this.addLabel.bind(this)}
								onRemove={this.removeLabel.bind(this)}
								selectedGroupName={formatMessage({id: 'common.added'})}
								showLabelsBelow
								name={formatMessage({
									id: 'settings_labels.add-label',
								})}
							/>
						</div>
					</div>
				)}
				<div className={'radio-options'}>
					<div className="duplicate-project-use-sprints">
						{this.props.duplicateDisabled ? null : (
							<div className="duplicate-toggle">
								<label className="label">{formatMessage({id: 'new_project.duplicate'})}</label>
								<div className="radio-button-controls-wrapper">
									<RadioButton
										onSelected={this.props.handleCheckboxChange.bind(this, true, 'useDuplication')}
										value={'yes'}
										stateValue={this.props.useDuplication ? 'yes' : 'no'}
										cy={'duplicate-project-yes'}
									/>
									<span className="info-label control-label margin-right">
										{formatMessage({id: 'common.yes'})}
									</span>
									<RadioButton
										onSelected={this.props.handleCheckboxChange.bind(this, false, 'useDuplication')}
										value={'no'}
										stateValue={this.props.useDuplication ? 'yes' : 'no'}
										cy={'duplicate-project-no'}
									/>
									<span className="info-label control-label margin-right">
										{formatMessage({id: 'common.no'})}
									</span>
								</div>
							</div>
						)}

						{this.props.useDuplication ? null : this.props.canChangeSprintSettings ? (
							<div className="sprints">
								<div className={'sprints-radio'}>
									<label className="label">{formatMessage({id: 'new_project_modal.use_sprints'})}</label>
									<div className="radio-button-controls-wrapper">
										<RadioButton
											onSelected={this.props.handleCheckboxChange.bind(this, true, 'useSprints')}
											value={'use_sprints_selected'}
											stateValue={
												this.props.useSprints
													? 'use_sprints_selected'
													: 'use_sprints_selected_not_selected'
											}
											cy={'use-sprints-yes'}
										/>
										<span className="info-label control-label margin-right">
											{formatMessage({id: 'common.yes'})}
										</span>
										<RadioButton
											onSelected={this.props.handleCheckboxChange.bind(this, false, 'useSprints')}
											value={'use_sprints_selected_not_selected'}
											stateValue={
												this.props.useSprints
													? 'use_sprints_selected'
													: 'use_sprints_selected_not_selected'
											}
											cy={'use-sprints-no'}
										/>
										<span className="info-label control-label margin-right">
											{formatMessage({id: 'common.no'})}
										</span>
									</div>
									<div className={'white-space'} />
								</div>
								{this.props.useSprints ? (
									<InputFieldV2
										id={'project-sprints-length'}
										autoFocus={true}
										type="number"
										value={this.props.sprintLengthInDays}
										label={formatMessage({id: 'new_project.sprint_length_v2'})}
										onChange={this.props.handleSprintLengthChange.bind(this)}
										placeholder="14"
										suffix={formatMessage({id: 'common.days'}).toLowerCase()}
										cy={'sprint-length'}
									/>
								) : null}
							</div>
						) : null}
					</div>
					{this.props.useDuplication ? (
						<div className="duplicate-project-use-sprints">
							{hasFeatureFlag('create_project_duplicate_dropdown_fetch') ? (
								<ForecastQueryRenderer
									key="query-render-DuplicateProjectDropdown"
									query={DuplicateProjectDropdownQuery}
									render={(relayProps, retry) => {
										return (
											<DuplicateProjectDropdown
												{...relayProps}
												handleDuplicateProjectSelectChange={
													this.props.handleDuplicateProjectSelectChange
												}
												projectToDuplicate={this.props.projectToDuplicate}
												label={formatMessage({id: 'project_settings.duplicate-project'})}
												placeholder={formatMessage({id: 'common.none'})}
												invalidInput={this.props.missingProjectToDuplicate}
												errorMessage={this.props.errorMessage}
											/>
										);
									}}
								/>
							) : (
								<DropdownV2
									options={this.props.duplicateOptions}
									onChange={this.props.handleProjectSelectChange.bind(this)}
									value={this.props.projectToDuplicate && this.props.projectToDuplicate.id}
									label={formatMessage({id: 'project_settings.duplicate-project'})}
									placeholder={formatMessage({id: 'common.none'})}
									clearable={true}
									required={true}
									invalidInput={this.props.missingProjectToDuplicate}
									errorMessage={this.props.errorMessage}
									customHeight={30}
									useInputHeight={true}
									inputCy={'projects-list'}
									listDataCy={'project'}
								/>
							)}
							{this.props.projectToDuplicate ? (
								<div className="duplicate-options">
									<label className="label">{formatMessage({id: 'common.duplicate'})}:</label>
									<div className="checkbox-controls-wrapper">
										<Checkbox
											onChange={this.props.handleSettingsChange.bind(this)}
											isChecked={this.props.duplicateSettings}
											cy={'duplicate-option-0'}
										/>
										<span
											className="info-label control-label"
											onClick={this.props.handleSettingsChange.bind(this)}
										>
											{formatMessage({id: 'duplicate-modal.settings-v2'})}
										</span>
									</div>
									<div className="checkbox-controls-wrapper">
										<Checkbox
											onChange={this.props.handleTasksChange.bind(this)}
											isChecked={this.props.duplicateTasks}
											cy={'duplicate-option-1'}
										/>
										<span
											className="info-label control-label"
											onClick={this.props.handleTasksChange.bind(this)}
										>
											{formatMessage({id: 'duplicate-modal.cards'})}
										</span>
									</div>

									<div className="checkbox-controls-wrapper">
										<Checkbox
											onChange={this.props.handlePhasesChange.bind(this)}
											isChecked={this.props.duplicatePhases}
											cy={'duplicate-option-2'}
										/>
										<span
											className="info-label control-label"
											onClick={this.props.handlePhasesChange.bind(this)}
										>
											{this.props.projectToDuplicate.useBaseline
												? formatMessage({id: 'duplicate-modal.scopes_baseline'})
												: formatMessage({id: 'duplicate-modal.scopes'})}
										</span>
									</div>
									<div className="checkbox-controls-wrapper">
										<Checkbox
											onChange={this.props.handleTeamChange.bind(this)}
											isChecked={this.props.duplicateTeam}
											cy={'duplicate-option-3'}
										/>
										<span
											className="info-label control-label"
											onClick={this.props.handleTeamChange.bind(this)}
										>
											{formatMessage({id: 'duplicate-modal.team'})}
										</span>
									</div>
									{this.props.duplicateTeam && isUsingAllocations && (
										<div className="checkbox-controls-wrapper">
											<Checkbox
												onChange={this.props.handleAllocationsChange.bind(this)}
												isChecked={this.props.duplicateAllocations}
												cy={'duplicate-option-7'}
											/>
											<span
												className="info-label control-label"
												onClick={this.props.handleAllocationsChange.bind(this)}
											>
												{formatMessage({id: 'duplicate-modal.allocations'})}
											</span>
										</div>
									)}
									{!CompanySetupUtil.hasFinance() ? null : (
										<>
											<div className="checkbox-controls-wrapper">
												<Checkbox
													onChange={this.props.handleExpensesChange.bind(this)}
													isChecked={this.props.duplicateExpenses}
													cy={'duplicate-option-4'}
												/>
												<span
													className="info-label control-label"
													onClick={this.props.handleExpensesChange.bind(this)}
												>
													{formatMessage({id: 'project_budget.expenses'})}
												</span>
											</div>
											<div className="checkbox-controls-wrapper">
												<Checkbox
													onChange={this.props.handleRetainerPeriodsChange.bind(this)}
													isChecked={this.props.duplicateRetainerPeriods}
													cy={'duplicate-option-8'}
												/>
												<span
													className="info-label control-label"
													onClick={this.props.handleRetainerPeriodsChange.bind(this)}
												>
													{formatMessage({id: 'duplicate-modal.retainer_periods'})}
												</span>
											</div>
										</>
									)}
									<div className="checkbox-controls-wrapper">
										<Checkbox
											onChange={this.props.handleColorChange.bind(this)}
											isChecked={this.props.duplicateColor}
											cy={'duplicate-option-5'}
										/>
										<span
											className="info-label control-label"
											onClick={this.props.handleColorChange.bind(this)}
										>
											{formatMessage({id: 'project_settings.project-color'})}
										</span>
									</div>
									{hasPlaceholders && (
										<div className="checkbox-controls-wrapper">
											<Checkbox
												onChange={this.props.handlePlaceholdersChange.bind(this)}
												isChecked={this.props.duplicatePlaceholders}
												cy={'duplicate-option-6'}
											/>
											<span
												className="info-label control-label"
												onClick={this.props.handlePlaceholdersChange.bind(this)}
											>
												{formatMessage({id: 'duplicate-modal.placeholders'})}
											</span>
										</div>
									)}
								</div>
							) : null}
						</div>
					) : null}
				</div>
				{this.props.useDuplication ? (
					<div className="project-types empty" />
				) : (
					<div className={'project-types'} data-cy={'project-types'}>
						{hasSomePermission([
							PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
							PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
						]) ? ( // this part is only for financial people
							<div className={'budget-type'}>
								<div className="budget-type-options">
									{!isFeatureHidden(HIDDEN_FEATURES.BUDGET_TYPES) && (
										<>
											<label className="label tooltip">
												{formatMessage({id: 'new_project_modal.budget_type'})}

												<TooltipContainer
													infoText={[
														{
															title: ['new_project_modal.budget_type_fixed_price'],
															details: ['new_project_modal.budget_type_fixed_hours_info'],
														},
														{
															title: ['new_project_modal.budget_type_time_materials'],
															details: ['new_project_modal.budget_type_time_materials_info'],
														},
														{
															title: ['common.non-billable'],
															details: ['new_project_modal.budget_type_non_billable_info'],
														},
														{
															title: ['new_project_modal.retainer'],
															details: ['new_project_modal.retainer_info'],
															hide: !Util.shouldUseRetainers(this.props.modules),
														},
													]}
													translatedMessage={false}
													tooltipInfinteDuration={true}
													edgeOffset={350}
												>
													<InformationIcon />
												</TooltipContainer>
											</label>

											<ProjectBudgetTypeControls
												budgetType={this.props.budgetType}
												useRetainers={Util.shouldUseRetainers(this.props.modules)}
												project={null}
												hasFinance={CompanySetupUtil.hasFinance()}
												currency={this.props.companyCurrency}
												defaultPeriodBudgetType={this.props.defaultPeriodBudgetType}
												handledefaultPeriodChange={this.props.handledefaultPeriodChange}
												defaultPeriodHoursAmount={this.props.defaultPeriodHoursAmount}
												defaultPeriodPriceAmount={this.props.defaultPeriodPriceAmount}
												onPeriodBudgetTypeChange={this.props.onPeriodBudgetTypeChange}
												defaultPeriodPeriodicity={this.props.defaultPeriodPeriodicity}
												wrongPeriodLength={this.props.wrongPeriodLength}
												wrongPeriodAmount={this.props.wrongPeriodAmount}
												errorMessage={this.props.errorMessage}
												defaultPeriodLength={this.props.defaultPeriodLength}
												handlePeriodLengthChange={this.props.handlePeriodLengthChange}
												onPeriodicityChange={this.props.onPeriodicityChange}
												handleFixedPriceBudget={this.props.handleFixedPriceBudget}
												fixedPriceBudget={this.props.fixedPriceBudget}
												rateCardSelected={this.props.rateCardSelected}
												invalidFixedPriceFormat={this.props.invalidFixedPriceFormat}
												onBudgetTypeChange={this.props.onBudgetTypeChange}
												handleSelectRateChange={this.props.handleSelectRateChange}
												rate_card_options={this.getRateCardsOptions()}
											/>
										</>
									)}
									{this.props.budgetType === BUDGET_TYPE.RETAINER && (
										<div className="budget-type-specific-values">
											<div className={'price-section ' + this.props.budgetType.toLowerCase()}>
												<div className="pre-create-periods">
													<div className="spacer" />
													<div className="inputs-section">
														<div className="radio-options">
															<div>
																<label className="label">
																	{formatMessage({id: 'retainer.pre_create_periods'})}
																</label>
																<div
																	className="radio-button-controls-wrapper"
																	title={
																		!this.props.projectStartDate
																			? formatMessage({id: 'common.select_start_date'})
																			: ''
																	}
																>
																	<RadioButton
																		onSelected={this.props.handleCheckboxChange.bind(
																			this,
																			true,
																			'preCreatePeriods'
																		)}
																		value={'yes'}
																		stateValue={this.props.preCreatePeriods ? 'yes' : 'no'}
																		locked={!this.props.projectStartDate}
																		cy={'pre-create-periods-yes'}
																	/>
																	<span
																		className={
																			'info-label control-label margin-right' +
																			(!this.props.projectStartDate ? ' disabled' : '')
																		}
																	>
																		{formatMessage({id: 'common.yes'})}
																	</span>
																	<RadioButton
																		onSelected={this.props.handleCheckboxChange.bind(
																			this,
																			false,
																			'preCreatePeriods'
																		)}
																		value={'no'}
																		stateValue={this.props.preCreatePeriods ? 'yes' : 'no'}
																		locked={!this.props.projectStartDate}
																		cy={'pre-create-periods-no'}
																	/>
																	<span
																		className={
																			'info-label control-label margin-right' +
																			(!this.props.projectStartDate ? ' disabled' : '')
																		}
																	>
																		{formatMessage({id: 'common.no'})}
																	</span>
																</div>
															</div>
														</div>

														{this.props.preCreatePeriods && this.props.projectStartDate ? (
															<div className={'pre-create-input'}>
																<InputFieldV2
																	id={'pre-create-amount'}
																	autoFocus={true}
																	type="number"
																	onChange={this.props.handleNumberPeriodChange.bind(this)}
																	onBlur={this.props.onNumberPeriodChangeBlur.bind(this)}
																	value={this.props.preCreateAmount}
																	label={formatMessage({id: 'retainer.period-amount'})}
																	placeholder="1"
																	step={1}
																	minNumber={1}
																	cy={'pre-create-amount-input'}
																/>
															</div>
														) : !this.props.projectStartDate ? (
															<div className={'info-message'}>
																{formatMessage({id: 'retainer.no_dates_tagline'})}{' '}
															</div>
														) : null}
													</div>
													{this.props.preCreatePeriods &&
													this.props.preCreateAmount &&
													this.props.defaultPeriodLength &&
													((this.props.projectStartDate && this.props.defaultPeriodHoursAmount) ||
														this.props.defaultPeriodPriceAmount) ? (
														<div
															className="retainer-totals"
															data-cy={'pre-create-periods-retainer-totals'}
														>
															<div className={'retainer-totals-container dates-section'}>
																<label className={'label'}>
																	{formatMessage({id: 'common.dates'})}
																</label>
																<div className={'retainer-bloc dates'}>
																	<div className={'start-period-date'}>
																		<FixedRangePicker
																			useTextFormat={true}
																			hideDay={true}
																			useSinglePicker={true}
																			usePropsDate={true}
																			disableHoverColor={true}
																			rangeType={'day'}
																			showAsText={false}
																			customBackgroundColor={'#f6f6f6'}
																			useCompactStyle={true}
																			calendarOffsetX={-84}
																			hideLabel={true}
																			startDate={this.props.periodStartDate.clone()}
																			showEmptyDate={true}
																			handleDateRangeChange={start =>
																				this.props.changePeriodStartDate(start)
																			}
																			showOnlyIcon={true}
																			hidePointerArrow={true}
																			tooltipProps={{
																				infoText: formatMessage({
																					id: 'retainer.change_first_period_start_date',
																				}),
																			}}
																			setHoveredDay={day => this.props.setHoveredDay(day)}
																		/>
																	</div>
																	{this.props.periodStartDateHovered
																		? this.props.periodStartDateHovered.format('ll')
																		: this.props.periodStartDate.format('ll')}{' '}
																	- {lastPeriodEndDate.format('ll')}
																</div>
															</div>
															{this.props.defaultPeriodBudgetType !==
															PERIOD_BUDGET_TYPE.TIME_AND_MATERIALS ? (
																<div className={'retainer-totals-container value-section'}>
																	<label className={'label'}>
																		{formatMessage({id: 'retainer.total_value'})}
																	</label>
																	<div className={'retainer-bloc value'}>
																		<div className={'inner-text'}>
																			{this.props.defaultPeriodBudgetType ===
																			PERIOD_BUDGET_TYPE.FIXED_HOURS
																				? this.props.defaultPeriodHoursAmount *
																						this.props.preCreateAmount +
																				  'h'
																				: Util.getFormattedCurrencyValue(
																						Util.GetCurrencySymbol(
																							this.props.companyCurrency
																						),
																						this.props.defaultPeriodPriceAmount *
																							this.props.preCreateAmount
																				  )}
																		</div>
																	</div>
																</div>
															) : null}
														</div>
													) : null}
												</div>
											</div>
										</div>
									)}
									{this.props.budgetType !== BUDGET_TYPE.RETAINER && (
										<div className="budget-type-specific-values">
											<div className="inputs-section">
												<div className="radio-options">
													<div>
														{!Util.hasBaselineModule(this.props.modules) ? (
															<>
																<label
																	style={{color: '#a1a1a1'}}
																	title={
																		!Util.hasBaselineModule(this.props.modules) &&
																		this.props.intl.formatMessage({
																			id: 'common.requires_financial_module',
																		})
																	}
																	className="label"
																>
																	{formatMessage({id: 'new_project_modal.use_baseline'})}
																</label>
																<FinancialLockIcon />
															</>
														) : (
															<label className="label">
																{formatMessage({id: 'new_project_modal.use_baseline'})}
															</label>
														)}
														<div className="radio-button-controls-wrapper">
															<RadioButton
																locked={!Util.hasBaselineModule(this.props.modules)}
																onSelected={this.props.handleCheckboxChange.bind(
																	this,
																	true,
																	'useBaseline'
																)}
																value={'use_baseline_selected'}
																stateValue={
																	this.props.useBaseline
																		? 'use_baseline_selected'
																		: 'use_baseline_not_Selected'
																}
																cy={'use-baseline-yes'}
															/>
															<span className="info-label control-label margin-right">
																{formatMessage({id: 'common.yes'})}
															</span>
															<RadioButton
																locked={!Util.hasBaselineModule(this.props.modules)}
																onSelected={this.props.handleCheckboxChange.bind(
																	this,
																	false,
																	'useBaseline'
																)}
																value={'use_baseline_not_Selected'}
																stateValue={
																	this.props.useBaseline
																		? 'use_baseline_selected'
																		: 'use_baseline_not_Selected'
																}
																cy={'use-baseline-no'}
															/>
															<span className="info-label control-label margin-right">
																{formatMessage({id: 'common.no'})}
															</span>
														</div>
														<div className={'white-space'} />
													</div>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
						) : (
							<div className={'budget-type empty'} />
						)}
					</div>
				)}
				<div className={'separation'}>
					<div className={'separation-line'} />
				</div>
			</div>
		);
	}
}
NewProjectModalDetailedSettings.propTypes = {
	modules: PropTypes.arrayOf(PropTypes.shape({moduleType: PropTypes.string.isRequired})).isRequired,
	canChangeSprintSettings: PropTypes.bool,
	useSprints: PropTypes.bool.isRequired,
	projectToDuplicate: PropTypes.object,
	missingProjectToDuplicate: PropTypes.bool,
	errorMessage: PropTypes.string,
	sprintLengthInDays: PropTypes.number,
	handleSprintLengthChange: PropTypes.func.isRequired,
	useDuplication: PropTypes.bool.isRequired,
	labels: PropTypes.array.isRequired,
	handleCheckboxChange: PropTypes.func.isRequired,
	handleSettingsChange: PropTypes.func,
	handleTasksChange: PropTypes.func,
	handlePhasesChange: PropTypes.func,
	handleTeamChange: PropTypes.func,
	handlePlaceholdersChange: PropTypes.func,
	handleExpensesChange: PropTypes.func,
	duplicateSettings: PropTypes.bool,
	duplicateTasks: PropTypes.bool,
	duplicatePhases: PropTypes.bool,
	duplicateTeam: PropTypes.bool,
	duplicatePlaceholders: PropTypes.bool,
	duplicateExpenses: PropTypes.bool,
	duplicateAllocations: PropTypes.bool,
	duplicateRetainerPeriods: PropTypes.bool,
	useManualAllocations: PropTypes.bool.isRequired,
	onProjectTypeChange: PropTypes.func.isRequired,
	budgetType: PropTypes.string.isRequired,
	onBudgetTypeChange: PropTypes.func.isRequired,
	handleFixedPriceBudget: PropTypes.func.isRequired,
	invalidFixedPriceFormat: PropTypes.bool.isRequired,
	rateCardSelected: PropTypes.object,
	companyCurrency: PropTypes.string.isRequired,
	fixedPriceBudget: PropTypes.number,
	handleSelectRateChange: PropTypes.func.isRequired,
	rateCards: PropTypes.array.isRequired,
	onPeriodicityChange: PropTypes.func.isRequired,
	defaultPeriodPeriodicity: PropTypes.string,
	wrongPeriodLength: PropTypes.bool,
	wrongPeriodAmount: PropTypes.bool,
	defaultPeriodLength: PropTypes.number,
	defaultPeriodBudgetType: PropTypes.string,
	onPeriodBudgetTypeChange: PropTypes.func,
	handlePeriodLengthChange: PropTypes.func,
	handledefaultPeriodChange: PropTypes.func,
	defaultPeriodHoursAmount: PropTypes.number,
	defaultPeriodPriceAmount: PropTypes.number,
	projectStartDate: PropTypes.object,
	preCreatePeriods: PropTypes.bool.isRequired,
	handleNumberPeriodChange: PropTypes.func.isRequired,
	preCreateAmount: PropTypes.bool,
	periodStartDate: PropTypes.object,
	duplicateOptions: PropTypes.array,
	periodStartDateHovered: PropTypes.object,
	handleProjectSelectChange: PropTypes.func,
	handleDuplicateProjectSelectChange: PropTypes.func,
	duplicateDisabled: PropTypes.bool,
	createNewLabel: PropTypes.func.isRequired,
	updateLabels: PropTypes.func.isRequired,
	availableFeatureFlags: PropTypes.array.isRequired,
	tier: PropTypes.string.isRequired,
	changePeriodStartDate: PropTypes.func.isRequired,
	isUsingProjectAllocation: PropTypes.bool,
	isUsingMixedAllocation: PropTypes.bool,
};
NewProjectModalDetailedSettings.defaultProps = {
	canChangeSprintSettings: true,
};
export default injectIntl(NewProjectModalDetailedSettings);
